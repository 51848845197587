/* eslint-disable no-dupe-else-if */
/* eslint-disable no-lonely-if */
/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/no-array-callback-reference */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/no-array-index-key */
import Skeleton from 'components/Skeleton'
import { useContext, useEffect } from 'react'
import { AuthContext } from 'context/AuthContext'
import { useSelector } from 'react-redux'
import { DashboardService } from '../services/dashboard.service'
import {
	selectMeetings,
	selectMeetingStatus
} from '../redux/dashboardHome.slice'
import type { ICalendarEvent } from 'features/companies/interfaces/calendarEvent.interface'
import PastEventsAccordion from 'components/PastEventsAccordion'
import { RRule } from 'rrule'
import moment from 'moment-timezone'
import FutureEvents from 'components/futureEvents'
import CurrentEvents from 'components/currentEvents'
import OngoingEvents from 'components/ongoingEvents'
import { addMinutes, isAfter, isBefore, isSameDay } from 'date-fns'

export default function DashboardMeetings() {
	const { authUser } = useContext(AuthContext)
	const meetings = useSelector(selectMeetings)
	const loading = useSelector(selectMeetingStatus)

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = DashboardService.listenToFundDashboardMeetings({
			fundId: authUser.fund.id
		})
		return () => unsubscribe()
	}, [authUser?.fund.id])
	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = DashboardService.listenToFundDashboardMeetings({
			fundId: authUser.fund.id
		})
		return () => unsubscribe()
	}, [authUser?.fund.id])

	if (loading) {
		return (
			<div className='flex flex-col gap-3'>
				{['Today', 'Yesterday'].map(period => (
					<div
						key={period}
						className='flex flex-col gap-3 rounded-md border p-3'
					>
						<div>{period}</div>
						<div>
							{['', '', '', ''].map((_, index) => (
								<div key={index} className='flex gap-5'>
									<div className='mt-[0.6rem]'>
										<Skeleton className='h-3 w-16 rounded-md border' />
									</div>
									<div className='flex flex-col gap-3'>
										<div className='flex items-center gap-1'>
											<Skeleton className='h-8 w-8 rounded-full border' />
											<Skeleton className='h-8 w-8 rounded-full border' />
											<Skeleton className='ml-5 h-3 w-96 rounded-md border' />
										</div>
										<Skeleton className='h-16 w-full rounded-md border' />
										<div className='flex w-full items-end justify-end'>
											<Skeleton className='h-3 w-32 rounded-md border' />
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		)
	}
	if (loading) {
		return (
			<div className='flex flex-col gap-3'>
				{['Today', 'Yesterday'].map(period => (
					<div
						key={period}
						className='flex flex-col gap-3 rounded-md border p-3'
					>
						<div>{period}</div>
						<div>
							{['', '', '', ''].map((_, index) => (
								<div key={index} className='flex gap-5'>
									<div className='mt-[0.6rem]'>
										<Skeleton className='h-3 w-16 rounded-md border' />
									</div>
									<div className='flex flex-col gap-3'>
										<div className='flex items-center gap-1'>
											<Skeleton className='h-8 w-8 rounded-full border' />
											<Skeleton className='h-8 w-8 rounded-full border' />
											<Skeleton className='ml-5 h-3 w-96 rounded-md border' />
										</div>
										<Skeleton className='h-16 w-full rounded-md border' />
										<div className='flex w-full items-end justify-end'>
											<Skeleton className='h-3 w-32 rounded-md border' />
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		)
	}

	// Helper function to convert EXDATE with timezones
	function getExcludedDates(recurrence: string[]): Date[] {
		return recurrence
			.filter(rule => rule.startsWith('EXDATE;TZID='))
			.flatMap(rule => {
				const [tzid, dateList] = rule.split(':') // Split timezone and date part
				// eslint-disable-next-line @typescript-eslint/prefer-destructuring
				const timezone = tzid.split('=')[1] // Extract timezone from EXDATE;TZID=
				return dateList.split(',').map(dateStr => {
					// Parse the date string and convert to UTC using moment-timezone
					return moment.tz(dateStr, 'YYYYMMDDTHHmmss', timezone).toDate()
				})
			})
	}
	// Grouping function
	function groupEvents() {
		const now = new Date()
		const pastEvents: ICalendarEvent[] = []
		const futureEvents: ICalendarEvent[] = []
		const currentEvents: ICalendarEvent[] = []
		const ongoingEvents: ICalendarEvent[] = []

		const recurringEventIds = new Set<string>()

		meetings.forEach((event: ICalendarEvent) => {
			const startDate = new Date(event.start.dateTime || event.start.date || '')
			const originalStartTime = {
				hours: startDate.getUTCHours(),
				minutes: startDate.getUTCMinutes(),
				seconds: startDate.getUTCSeconds()
			}

			const endDate = new Date(event.end.dateTime || event.end.date || '')

			let isToday = isSameDay(startDate, now)

			if (event.recurrence || event.recurringEventId) {
				const uniqueRecurrenceId =
					event.recurringEventId || event.recurrence?.[0]

				if (uniqueRecurrenceId && !recurringEventIds.has(uniqueRecurrenceId)) {
					recurringEventIds.add(uniqueRecurrenceId) // Track uniqueness

					let nextOccurrence = startDate
					let prevOccurrence: Date | null = null
					let pastOccurrence: Date | null = null
					let excludedDates: Date[] = []

					if (event.recurrence) {
						// Handle excluded dates
						excludedDates = getExcludedDates(event.recurrence)

						const rruleString =
							event.recurrence.find(rule => rule.startsWith('RRULE:')) || ''

						if (rruleString) {
							const rrule = RRule.fromString(rruleString.replace('RRULE:', ''))

							// Get today's date
							const today = new Date()

							// Get the previous occurrence
							const prevDate = rrule.before(now, false)

							if (prevDate && isSameDay(prevDate, today)) {
								isToday = true

								prevOccurrence = new Date(
									Date.UTC(
										prevDate.getUTCFullYear(),
										prevDate.getUTCMonth(),
										prevDate.getUTCDate(),
										originalStartTime.hours,
										originalStartTime.minutes,
										originalStartTime.seconds
									)
								)

								const past = rrule.before(prevDate, true)
								if (past) pastOccurrence = past
							} else {
								pastOccurrence = prevDate
							}

							// Find the next occurrence after the current date
							const nextDate = rrule.after(now, false)
							if (!nextDate) return
							if (
								nextDate &&
								!excludedDates.some(
									exDate => exDate.getTime() === nextDate.getTime()
								)
							) {
								// Set next occurrence with the same time as the original event
								nextOccurrence = new Date(
									Date.UTC(
										nextDate.getUTCFullYear(),
										nextDate.getUTCMonth(),
										nextDate.getUTCDate(),
										originalStartTime.hours,
										originalStartTime.minutes,
										originalStartTime.seconds
									)
								)
							}
						}
					}

					if (
						isToday &&
						isAfter(now, nextOccurrence) &&
						isBefore(now, addMinutes(nextOccurrence, endDate.getUTCMinutes()))
					) {
						// Ongoing recurring event
						ongoingEvents.push({
							...event,
							start: { ...event.start, dateTime: nextOccurrence.toISOString() },
							summary: `${event.summary} - Live Now (Recurring)`
						})
					} else if (isToday && isSameDay(nextOccurrence, now)) {
						currentEvents.push({
							...event,
							start: { ...event.start, dateTime: nextOccurrence.toISOString() },
							summary: `${event.summary} - (Recurring)`
						})
					}

					if (nextOccurrence > now) {
						// Future recurring event
						futureEvents.push({
							...event,
							start: { ...event.start, dateTime: nextOccurrence.toISOString() },
							summary: `${event.summary} - (Recurring)`
						})
					}

					if (
						prevOccurrence &&
						isAfter(now, prevOccurrence) &&
						isBefore(now, addMinutes(prevOccurrence, endDate.getUTCMinutes()))
					) {
						ongoingEvents.push({
							...event,
							start: {
								...event.start,
								dateTime: prevOccurrence.toISOString()
							},
							summary: `${event.summary} `
						})
					} else if (prevOccurrence && isSameDay(prevOccurrence, now)) {
						currentEvents.push({
							...event,
							start: {
								...event.start,
								dateTime: prevOccurrence.toISOString()
							},
							summary: `${event.summary} `
						})
					}

					if (pastOccurrence && !isSameDay(pastOccurrence, now)) {
						pastEvents.push({
							...event,
							start: {
								...event.start,
								dateTime: pastOccurrence.toISOString()
							}
						})
					}
				}
			} else {
				// Non-recurring event handling
				if (endDate < now) {
					pastEvents.push(event)
				} else if (startDate <= now && endDate >= now) {
					ongoingEvents.push({
						...event,
						summary: `${event.summary} - Live Now`
					})
				} else if (isToday && startDate > now) {
					currentEvents.push(event)
				} else if (startDate > now) {
					futureEvents.push(event)
				}
			}
		})

		// Sort events by start date and time
		const sortByStartDateTime = (a: ICalendarEvent, b: ICalendarEvent) => {
			const aDate = new Date(a.start.dateTime || a.start.date || '').getTime()
			const bDate = new Date(b.start.dateTime || b.start.date || '').getTime()
			return aDate - bDate
		}

		// Sorting each category
		pastEvents.sort(sortByStartDateTime)
		ongoingEvents.sort(sortByStartDateTime)
		currentEvents.sort(sortByStartDateTime)
		futureEvents.sort(sortByStartDateTime)

		// Check for future events that are now past due and move them to pastEvents
		futureEvents.forEach((futureEvent, index) => {
			const futureStartDate = new Date(
				futureEvent.start.dateTime || futureEvent.start.date || ''
			)
			if (futureStartDate < now) {
				pastEvents.push(futureEvent)
				futureEvents.splice(index, 1) // Remove from futureEvents
			}
		})

		return { pastEvents, ongoingEvents, currentEvents, futureEvents }
	}

	// Run the grouping
	const { pastEvents, ongoingEvents, currentEvents, futureEvents } =
		groupEvents()

	return (
		<div className=''>
			{ongoingEvents.length > 0 && (
				<OngoingEvents ongoingEvents={ongoingEvents} />
			)}

			{currentEvents.length > 0 && (
				<CurrentEvents currentEvents={currentEvents} />
			)}

			{futureEvents.length > 0 && <FutureEvents futureEvents={futureEvents} />}

			{pastEvents.length > 0 && (
				<PastEventsAccordion pastEvents={pastEvents.reverse()} />
			)}
		</div>
	)
}
