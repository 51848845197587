import type { ICompany, ILimitedPartner, User } from 'interfaces'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where,
	writeBatch
} from 'firebase/firestore'
import {
	setIsRelevantCompaniesLoading,
	setLP,
	setLPError,
	setLPLoading,
	setRelevantCompanies
} from '../redux/lp.slice'

import { Errorhandler } from 'lib/sentry'
import Logger from 'lib/logger'
import { companiesRef } from 'features/companies/api/companies.api'
import { firestore } from 'lib/firebase'
import { getConvertedAmount } from 'utils/currency'
import { setLPs } from 'features/limited_partners/redux/lps.slice'
/* eslint-disable @typescript-eslint/prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'

export const lpsRef = collection(firestore, 'lps')

export const addLP = async (lp: ILimitedPartner): Promise<ILimitedPartner> => {
	const docRef = doc(lpsRef, lp.id)
	await setDoc(docRef, lp, { merge: true })
	return lp
}

export const getLP = async (id: string): Promise<ILimitedPartner> => {
	const docRef = doc(lpsRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as ILimitedPartner
}

export const getAllLP = async (): Promise<ILimitedPartner[]> => {
	const docRef = query(lpsRef)
	const docSnap = await getDocs(docRef)
	return docSnap.docs.map(d => d.data() as ILimitedPartner)
}

export const deleteLP = async (id: string): Promise<void> => {
	const docRef = doc(lpsRef, id)
	await deleteDoc(docRef)
}

export const getLPs = async (fundId: string): Promise<ILimitedPartner[]> => {
	const q = query(lpsRef, where('fund.id', '==', fundId))
	const querySnapshot = await getDocs(q)
	const lps = querySnapshot.docs.map(d => d.data() as ILimitedPartner)
	return lps
}

export const updateLP = async (
	lp: Partial<ILimitedPartner>
): Promise<Partial<ILimitedPartner>> => {
	const docRef = doc(lpsRef, lp.id)
	await updateDoc(docRef, lp as any, { merge: true })
	return lp
}

export const updateLPs = async (
	lps: Partial<ILimitedPartner>[]
): Promise<Partial<ILimitedPartner>[]> => {
	const batch = writeBatch(firestore)
	for (const lp of lps) {
		const docRef = doc(lpsRef, lp.id)
		batch.update(docRef, lp as never)
	}
	await batch.commit()
	return lps
}

export const listenToLPs = (fundId: string) => {
	const q = query(
		lpsRef,
		where('fund.id', '==', fundId),
		where('isApproved', '==', true)
	)
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const lps = querySnapshot.docs.map(d => d.data() as ILimitedPartner)
			const newData = lps.map(lp => ({
				...lp,
				totalCommittedConverted: getConvertedAmount(lp.totalCommitted),
				totalCalledConverted: getConvertedAmount(lp.totalCalled)
			}))

			store.dispatch(setLPs(newData as ILimitedPartner[]))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const listenToProspects = () => {
	const q = query(lpsRef, where('isApproved', '==', 'true'))
	const unsubscribe = onSnapshot(q, querySnapshot => {
		const lps = querySnapshot.docs.map(d => d.data() as ILimitedPartner)

		store.dispatch(setLPs(lps))
	})

	return unsubscribe
}

export const listenToLpEmail = (authUser: User) => {
	store.dispatch(setLPLoading(true))

	const docRef = query(
		lpsRef,
		where('email', '==', authUser.email),
		where('fund.id', '==', authUser.fund.id)
	)
	const unsubscribe = onSnapshot(
		docRef,
		docSnap => {
			if (docSnap.empty) {
				store.dispatch(setLPError('LP not found'))
			}

			const lp = docSnap.docs.map(d => d.data() as ILimitedPartner)[0]
			store.dispatch(setLP(lp))
		},
		error => {
			store.dispatch(setLPError(error.message))

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	)

	return unsubscribe
}
export const listenToLP = (id: string, authUser: User) => {
	store.dispatch(setLPLoading(true))

	const docRef = query(
		lpsRef,
		where('id', '==', id),
		where('fund.id', '==', authUser.fund.id)
	)
	const unsubscribe = onSnapshot(
		docRef,
		docSnap => {
			if (docSnap.empty) {
				store.dispatch(setLPError('LP not found'))
			}

			const lp = docSnap.docs.map(d => d.data() as ILimitedPartner)[0]
			store.dispatch(setLP(lp))
		},
		error => {
			store.dispatch(setLPError(error.message))

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	)

	return unsubscribe
}

export const listenToRelevantCompanies = (tagIds: string[], fundId: string) => {
	Logger.info(tagIds)
	store.dispatch(setIsRelevantCompaniesLoading(true))

	const q = query(
		companiesRef,
		where('fund.id', '==', fundId),
		where('tagIds', 'array-contains-any', tagIds)
	)
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const data = querySnapshot.docs.map(d => d.data() as ICompany)
			store.dispatch(setRelevantCompanies(data))
		},
		error => {
			Logger.error(error)
			Errorhandler.captureException(error)
		}
	)

	return unsubscribe
}
