/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-useless-template-literals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable unicorn/consistent-function-scoping */
import { BookText, CopyPlus, Files, LogOut, Notebook } from 'lucide-react'
import clsxm from 'utils/clsxm'
import {
	selectSelectedTab,
	setSelectedTab
} from 'features/dataTable/hooks/customColumn.slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'app/hooks'
import Button from './Buttons/Button'
import { useLocation } from 'react-router-dom'

type LayoutProperties = React.ComponentPropsWithoutRef<'div'>

export function TabButton({ tabKey, label, icon: Icon }) {
	const dispatch = useDispatch()
	const selectedTab = useAppSelector(selectSelectedTab)
	const handleTabChange = tabKey => {
		dispatch(setSelectedTab(tabKey))
	}

	const isSelected = selectedTab === tabKey

	return (
		<button
			type='button'
			onClick={() => handleTabChange(tabKey)}
			className={`container mx-auto flex items-center gap-1 ${
				isSelected
					? 'bg-blue-700 text-white'
					: 'border border-blue-400 bg-white'
			} rounded-lg p-1.5 text-sm`}
		>
			<Icon
				className={`${isSelected ? 'text-white' : 'text-blue-500'} shrink-0`}
				size={18}
			/>{' '}
			{label}
		</button>
	)
}

export default function Layout({
	children,
	className,
	title
}: Readonly<LayoutProperties>): React.ReactElement {
	const { pathname } = useLocation()

	const tabs = [
		{ key: 'info', label: 'Info', icon: BookText },
		{ key: 'files', label: 'Files', icon: Files },
		{ key: 'updates', label: 'Updates', icon: CopyPlus },
		{ key: 'notes', label: 'Notes', icon: Notebook }
	]

	function onLogout() {
		localStorage.removeItem('sharedLinkEmail')
		window.location.reload()
	}

	return (
		<section className={clsxm('w-full', className)}>
			<div className='flex w-full items-center justify-between border-b px-5'>
				<div className='container z-[2] flex h-12 items-center justify-between bg-white  md:pr-10 dark:border-b-gray-800 dark:bg-[#121212] dark:text-gray-100'>
					<div className='flex items-center gap-3 '>
						<img src='/images/logo/Logo-1.png' className='h-6 w-24' alt='' />
						{title && <div className='border-l pl-3'>{title}</div>}
					</div>
				</div>
				{pathname.includes('/shared') && (
					<div className='block lg:hidden'>
						<Button variant='ghost' className='p-2' onClick={onLogout}>
							<LogOut size={15} color='red' />
						</Button>
					</div>
				)}
			</div>

			<div className=''>
				{pathname.includes('/shared') && (
					<div className='text-m m-3 mr-5 flex h-12 max-w-[420px] items-center gap-3 overflow-scroll bg-gray-50 hover:cursor-pointer lg:hidden'>
						{tabs.map(({ key, label, icon }) => (
							<TabButton key={key} tabKey={key} label={label} icon={icon} />
						))}
					</div>
				)}
			</div>

			<div className='mx-auto pt-0'>{children}</div>
		</section>
	)
}
