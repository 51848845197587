import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue
} from 'components/ui/select'
import { useEffect, useState } from 'react'

import { Input } from 'components/ui/input'
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import clsx from 'clsx'
import { nanoid } from 'nanoid'
import clsxm from 'utils/clsxm'

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSelect: (value: any | object | string) => void
	defaultValue: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options: { label: string; value: any | object | string }[]
	placeholder?: string
	isCombo?: boolean
	content?: React.ReactNode
	groupName?: string
	className?: string
	triggerClassName?: string
	label?: string
	allowSearch?: boolean
	searchPlaceHolder?: string
	isReadOnly?: boolean
	allowNewInput?: boolean
	id?: string
}

export function ComboSelect({
	options,
	id,
	defaultValue = '',
	onSelect,
	placeholder,
	label,
	triggerClassName,
	allowSearch = false,
	searchPlaceHolder,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	isCombo = true,
	content,
	groupName,
	className = 'w-[280px]',
	isReadOnly,
	allowNewInput = false
}: Readonly<Props>) {
	const [optionsResult, setOptionsResult] = useState(options)
	const [searchValue, setSearchValue] = useState('')

	function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
		const { value } = e.target
		if (allowNewInput) setSearchValue(value)
		const result = options.filter(option =>
			option.label.toLowerCase().includes(value.toLowerCase())
		)
		setOptionsResult(result)
	}

	useEffect(() => {
		setOptionsResult(options)
	}, [options])

	if (isReadOnly) {
		return (
			<div data-testid={`${id}-readonly-combo-select`}>
				{label && (
					<div className='mb-2 block text-xs font-bold text-gray-700'>
						{label}
					</div>
				)}
				<div className='group flex w-fit items-center gap-1 text-base font-semibold text-blue-700'>
					{options.find(option => option.value === defaultValue)?.label || '-'}
				</div>
			</div>
		)
	}

	return (
		<div data-testid={`${id}-editable-combo-select`}>
			{label && (
				<div className='mb-2 block text-xs font-bold text-gray-700'>
					{label}
				</div>
			)}
			<Select
				value={defaultValue}
				onValueChange={e => {
					onSelect(e)
				}}
			>
				{content ? (
					<SelectTrigger
						defaultValue='onlyContent'
						className={clsxm(
							'w-fit border-none border-transparent p-0 shadow-none focus:outline-none focus:ring-0 ',
							triggerClassName
						)}
					>
						{content}
					</SelectTrigger>
				) : (
					<SelectTrigger className={clsx(className, triggerClassName)}>
						<SelectValue placeholder={placeholder} />
					</SelectTrigger>
				)}
				<SelectContent className='z-[999999]'>
					{allowSearch && (
						<Input
							placeholder={searchPlaceHolder ?? 'Search'}
							className='mb-2'
							onChange={e => {
								onSearchChange(e as React.ChangeEvent<HTMLInputElement>)
							}}
						/>
					)}
					{optionsResult.length === 0 && !allowNewInput && (
						<div className='text-center text-gray-500'>No results found</div>
					)}
					{optionsResult.length === 0 &&
						allowNewInput &&
						searchValue &&
						searchValue.trim() !== '' && (
							<SelectGroup>
								<SelectItem key={nanoid()} value={searchValue}>
									{searchValue}
								</SelectItem>
							</SelectGroup>
						)}
					<SelectGroup>
						{groupName && <SelectLabel>{groupName}</SelectLabel>}
						{optionsResult.map(option => (
							<SelectItem key={nanoid()} value={option.value}>
								{option.label}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	)
}
