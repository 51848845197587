import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import DocTipView from '../../doctip/views/DocTipView'
import DocViewer from './DocViewer'
import EMLViewer from './EmlViewer'
import type { IFile } from 'features/files/file.interface'

/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, type LegacyRef } from 'react'
import PDFView from './PDFViewer'
import PictureView from './PictureViewer'
import { Trash2 } from 'lucide-react'
import VideoPlayer from './VideoViewer'
import clsxm from 'utils/clsxm'
import {
	Popover,
	PopoverContent,
	PopoverTrigger
} from '@radix-ui/react-popover'
import MoveFolder from 'features/folders/components/move.component'
import FoldersApi from 'features/folders/api/folders.api'
import type { ICompany } from 'interfaces'
import { ImFolderPlus } from 'react-icons/im'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import { toast } from 'sonner'

interface Props {
	rawFile?: File
	file?: any
	className?: string
	content?: any
	pdfTrigger?: LegacyRef<HTMLAnchorElement>
	variant?: 'default' | 'list'
	onDelete?: (file: IFile) => void
	readonly?: boolean
	isDownloadable?: boolean
	company?: ICompany
}

const mimeTypeImageMap: any = {
	'image/jpeg': '/images/picture-thumbnail.svg',
	'image/png': '/images/picture-thumbnail.svg',
	'image/tiff': '/images/picture-thumbnail.svg',
	'image/jif': '/images/picture-thumbnail.svg',

	'application/pdf': '/images/pdf-thumbnail.svg',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		'/images/word-thumbnail.svg',
	'application/msword': '/images/word-thumbnail.svg',

	'video/mp4': '/images/video-thumbnail.svg',
	'video/mpeg': '/images/video-thumbnail.svg',
	'video/webm': '/images/video-thumbnail.svg',
	'video/ogg': '/images/video-thumbnail.svg'
}

const defaultImage = 'https://via.placeholder.com/150'

function DefaultFile({
	file,
	deleteFile,
	readonly,
	company
}: {
	file: IFile
	deleteFile?: (file: IFile) => void
	readonly?: boolean
	company?: ICompany
}) {
	const imageUrl =
		file.thumbnails?.medium || mimeTypeImageMap[file.mimeType] || defaultImage
	const [showModal, setShowModal] = useState(false)
	const { allFolders, getAllFolders } = FoldersApi()
	const [fileId, setFileId] = useState('')
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
	const [showPopover, setShowPopover] = useState(false)

	useEffect(() => {
		if (company?.id) getAllFolders(company.id)
	}, [allFolders, company?.id, getAllFolders])

	async function addAsDeck() {
		setShowPopover(false)
		const companyDoc = doc(firestore, `companies/${company?.id}`)
		const loadingToastId = toast.loading('Adding deck...')

		try {
			const deck = {
				id: file.id,
				url: file.url,
				thumbnail: file.thumbnails?.large ?? '',
				name: file.name
			}

			await setDoc(
				companyDoc,
				{
					deck
				},
				{ merge: true }
			)
			toast.success('Added as deck')
		} catch {
			toast.error('Failed to set as deck')
		} finally {
			toast.dismiss(loadingToastId)
		}
	}

	function onPopoverOpenChange(open: boolean) {
		setShowPopover(open)
		if (!open) {
			setShowDeleteModal(false)
			setShowModal(false)
		}
	}

	return (
		<div className='flex-1 rounded border bg-white p-2'>
			<div className='flex w-full items-center justify-between'>
				<DocTipView file={file} />
				<div>
					{!readonly && deleteFile && (
						<div
							className='flex w-full justify-start'
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Popover open={showPopover} onOpenChange={onPopoverOpenChange}>
								<PopoverTrigger>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='size-5 font-bold'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z'
										/>
									</svg>
								</PopoverTrigger>
								<PopoverContent
									className={`PopoverContent z-50 rounded-md border  bg-white shadow-xl ${
										showModal || showDeleteModal ? 'w-[38em]' : 'w-[150px]'
									} text-sm`}
								>
									{showModal ? (
										<MoveFolder
											isFile
											wrapperClass='p-3'
											onClose={setShowModal}
											file={file}
											company={company as ICompany}
											folders={allFolders}
											fileId={fileId}
										/>
									) : (
										<div>
											{!showDeleteModal && (
												<>
													<button
														type='button'
														className='flex w-full items-center gap-2 p-3 hover:cursor-pointer hover:bg-gray-100'
														onClick={() => {
															setShowModal(true)
															setFileId(file.id)
														}}
													>
														<svg
															className='text-blue-500'
															xmlns='http://www.w3.org/2000/svg'
															width='20px'
															height='20px'
															viewBox='0 0 24 24'
														>
															<path
																fill='currentColor'
																d='M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h6l2 2h8q.825 0 1.413.588T22 8v10q0 .825-.587 1.413T20 20zm8.2-6l-1.625 1.625l1.4 1.4L16 13l-4.025-4.025l-1.4 1.4L12.2 12H8v2z'
															/>
														</svg>
														Move
													</button>
													<hr className='mx-3' />
													<button
														type='button'
														className='flex w-full items-center gap-2 p-3 hover:cursor-pointer hover:bg-gray-100'
														onClick={addAsDeck}
													>
														<ImFolderPlus
															color='green'
															size={17}
															className='mr-1'
														/>
														Add as deck
													</button>
													<hr className='mx-3' />
													<button
														type='button'
														className='flex w-full items-center gap-2 p-3 hover:cursor-pointer  hover:bg-red-100 hover:text-red-500'
														onClick={() => {
															setShowDeleteModal(true)
														}}
													>
														<Trash2 color='red' size={15} className='mr-1' />
														Delete
													</button>
												</>
											)}
										</div>
									)}
									{showDeleteModal && (
										<div className='p-3'>
											<p className='mb-3 text-base font-bold'>Delete</p>
											Are you sure you want to delete this{' '}
											<strong>{file.name}</strong>?
											<div className='mt-5 flex w-full justify-end gap-3'>
												<Button
													onClick={() => setShowDeleteModal(false)}
													variant='danger'
												>
													Cancel
												</Button>
												<Button onClick={() => deleteFile(file)}>Yes</Button>
											</div>
										</div>
									)}
								</PopoverContent>
							</Popover>
						</div>
					)}
				</div>
			</div>
			<div className='mt-1.5 flex h-32 cursor-pointer justify-center '>
				<img
					src={imageUrl ?? ''}
					alt='thumbnail'
					className={clsxm(
						!imageUrl.includes('/image') &&
							'w-full rounded-md border object-cover',
						imageUrl.includes('/image') && 'w-12'
					)}
				/>
			</div>
		</div>
	)
}

function ListView({
	file,
	onDelete,
	readonly
}: {
	file: IFile
	onDelete: (file: IFile) => void
	readonly?: boolean
}) {
	return (
		<div className='flex w-full items-center justify-between gap-3 border-b bg-white pb-2 text-sm'>
			{!readonly && (
				<Button
					onClick={() => {
						onDelete(file)
					}}
					variant='ghost'
					className='px-1 py-1'
				>
					<Trash2 color='red' size={18} />
				</Button>
			)}
			<div className='w-full'>{file.name}</div>
		</div>
	)
}

export default function FileViewer({
	file,
	className,
	pdfTrigger,
	rawFile,
	content,
	variant = 'default',
	onDelete,
	readonly = false,
	company,
	isDownloadable = false
}: Readonly<Props>) {
	// const [showModal, setShowModal] = useState(false)
	const fileExtension = file.name.split('.').pop() || file?.mimetype

	content ||=
		variant === 'list'
			? ListView({ file, onDelete, readonly })
			: DefaultFile({ file, deleteFile: onDelete, readonly, company })

	if (!file) return <div>{content}</div>

	if (file.oracleData?.id && file.source === 'oracle') {
		return (
			<div className='flex-1 rounded border bg-white p-2'>
				<div className='flex items-center'>
					<DocTipView file={file} />
					<div>
						{!readonly && onDelete && (
							<div
								className='flex w-full justify-start'
								onClick={e => {
									e.stopPropagation()
								}}
							>
								<DeleteModal
									deleteButtonId={`deleteFile-${file.name}`}
									title='Delete File'
									description={`Are you sure you want to delete ${file.name}?`}
									trigger={
										<Button
											id={`file-${file.name}-delete-button`}
											variant='ghost'
											className='px-1 py-1'
										>
											<Trash2 color='red' size={15} />
										</Button>
									}
									onDelete={() => {
										onDelete(file)
									}}
								/>
							</div>
						)}
					</div>
				</div>

				<div
					onClick={() => {
						// store.dispatch(setSelectedFile(file))
						// setShowModal(!showModal)
						window.open(file.url, '_blank', 'noopener, noreferrer')
					}}
				>
					<img
						style={{
							aspectRatio: 4 / 3,
							height: '150px'
						}}
						src={file.thumbnails?.medium || ''}
						alt='thumbnail'
						className='mt-2 w-full cursor-pointer rounded-md border object-cover '
					/>
				</div>
			</div>
		)
	}

	if (file.source === 'docsend') {
		return (
			<div className='flex-1 rounded border bg-white p-2'>
				<div className='flex items-center'>
					<DocTipView file={file} />
					<div>
						{!readonly && onDelete && (
							<div
								className='flex w-full justify-start'
								onClick={e => {
									e.stopPropagation()
								}}
							>
								<DeleteModal
									deleteButtonId={`deleteFile-${file.name}`}
									title='Delete File'
									description={`Are you sure you want to delete ${file.name}?`}
									trigger={
										<Button
											id={`file-${file.name}-delete-button`}
											variant='ghost'
											className='px-1 py-1'
										>
											<Trash2 color='red' size={15} />
										</Button>
									}
									onDelete={() => {
										onDelete(file)
									}}
								/>
							</div>
						)}
					</div>
				</div>

				<div
					onClick={() => {
						window.open(file.url, '_blank', 'noopener, noreferrer')
					}}
				>
					<img
						style={{
							aspectRatio: 4 / 3,
							height: '150px'
						}}
						// src={imageUrl || ''}
						src=''
						alt='thumbnail'
						className='mt-2 w-full cursor-pointer rounded-md border object-cover '
					/>
				</div>
			</div>
		)
	}

	if (
		(fileExtension && fileExtension === 'pdf') ||
		file.name?.endsWith('.pdf')
	) {
		return <PDFView file={file} content={content} />
	}

	if (['eml'].includes(fileExtension)) {
		return <EMLViewer emlFile={file} content={content} />
	}

	if (['jpg', 'webp', 'jpeg', 'png', 'gif', 'tif'].includes(fileExtension)) {
		return <PictureView file={file} content={content} />
	}

	if (['doc', 'docx', 'dotx', 'docm', 'dotm'].includes(fileExtension)) {
		return <DocViewer file={file} content={content} className='' />
	}

	if (['mp4', 'webm', 'mpeg', 'ogv', 'video/mp4'].includes(fileExtension)) {
		return <VideoPlayer file={file} content={content} />
	}

	return (
		<PDFView file={file} content={content} isDownloadable={isDownloadable} />
	)
}
