import './lib/sentry'
import './index.css'
// import { registerSW } from 'virtual:pwa-register'

import App from 'App'
import { NextUIProvider } from '@nextui-org/react'
import { Provider } from 'react-redux'
// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
// import { registerSW } from 'virtual:pwa-register'
import { store } from 'app/store'

// registerSW()

const container = document.querySelector('#root')

if (container) {
	const root = createRoot(container)
	root.render(
		// <StrictMode>
		<Provider store={store}>
			{/* <PersistGate loading={null} persistor={persistor}> */}
			<NextUIProvider>
				<App />
			</NextUIProvider>
			{/* </PersistGate> */}
		</Provider>
		// </StrictMode>
	)
}
