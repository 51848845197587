/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	DropdownMenuTrigger
} from './ui/dropdown-menu'

import { AuthContext } from 'context/AuthContext'
import { logout } from 'features/authentication/authentication.api'
import { toast } from 'sonner'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserRole } from 'interfaces'

const onLogout = () => {
	try {
		logout()
	} catch (error: any) {
		toast.error(error.message)
	}
}

export function UserNav() {
	const { authUser } = useContext(AuthContext)
	const navigate = useNavigate()

	return (
		<div className='mr-4' data-cy='user-nav'>
			<DropdownMenu>
				<DropdownMenuTrigger  asChild>
					<div
						className='relative h-8 w-8 cursor-pointer rounded-full'
						data-testid='user-nav-trigger'
					>
						<Avatar className='h-9 w-9 border'>
							<AvatarImage src={authUser?.photoUrl} alt='profile' />
							<AvatarFallback>{authUser?.name.charAt(0)}</AvatarFallback>
						</Avatar>
					</div>
				</DropdownMenuTrigger>
				<DropdownMenuContent className='w-56' align='end' forceMount>
					<DropdownMenuLabel
						className='cursor-pointer font-normal '
						// onClick={() => navigate('/profile')}
					>
						<div className='flex flex-col space-y-1'>
							<p className='text-sm font-medium leading-none'>
								{authUser?.name}
							</p>
							<p className='text-muted-foreground text-xs leading-none'>
								{authUser?.email}
							</p>
						</div>
					</DropdownMenuLabel>

					{![UserRole.TeamMember, UserRole.LimitedPartner].includes(
						authUser?.role as UserRole
					) && (
						<div data-testid="settings-profile-nav-item">
							<DropdownMenuSeparator />{' '}
							<DropdownMenuGroup>
								<DropdownMenuItem
									onClick={() => {
										navigate('/settings?tab=profile')
									}}
									className='cursor-pointer hover:bg-gray-100'
								>
									Settings
									<DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
								</DropdownMenuItem>
							</DropdownMenuGroup>
						</div>
					)}

					<DropdownMenuSeparator />
					<DropdownMenuItem
						data-cy='logout-button'
						className='cursor-pointer text-red-500 hover:bg-gray-100'
						onClick={() => onLogout()}
					>
						Log out
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	)
}
