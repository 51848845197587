import type { ForwardedRef } from 'react'
import React from 'react'

import type { AutosizeTextAreaRef } from './autosizetextarea'
import { AutosizeTextarea } from './autosizetextarea'
import clsx from 'clsx'
import { cn } from 'lib/utils'
import clsxm from 'utils/clsxm'

export type InputProps = React.InputHTMLAttributes<
	HTMLInputElement | HTMLTextAreaElement
>

type Props = InputProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error?: any
	touched?: boolean
	label?: string
	isRequired?: boolean
	side?: 'left' | 'top'
	isMultiline?: boolean
	subText?: string
	variant?: 'default' | 'outlined' | 'underlined'
	wrapperClass?: string
	labelClass?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}

const Input = React.forwardRef(
	(
		{
			type = 'text',
			className,
			error,
			touched,
			label,
			isMultiline = false,
			isRequired = false,
			side = 'top',
			variant = 'default',
			subText,
			wrapperClass,
			labelClass,
			...props
		}: Props,
		ref
	) => {
		// console.log('touched', touched)
		return (
			<div
				className={clsx(
					'flex',
					side === 'top' && 'flex-col',
					side === 'left' && 'flex-row items-center gap-2',
					wrapperClass
				)}
			>
				{label && (
					<label
						htmlFor={type}
						className={clsxm(
							'mb-2 block text-sm font-semibold capitalize',
							labelClass
						)}
					>
						{label}
						{isRequired && <span className='text-red-500'>*</span>}
					</label>
				)}
				{isMultiline ? (
					<AutosizeTextarea
						{...props}
						ref={ref as ForwardedRef<AutosizeTextAreaRef>}
						data-testid={props.id}
						className={cn(
							variant === 'default' &&
								'flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300',
							className,
							error && 'border-red-500',
							variant === 'outlined' && 'border-2',
							variant === 'underlined' &&
								'flex h-9 w-full rounded-md border-b border-slate-200 bg-red-500 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300'
						)}
					/>
				) : (
					<input
						type={type}
						data-testid={props.id}
						ref={ref as ForwardedRef<HTMLInputElement>}
						className={cn(
							variant === 'default' &&
								'flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300',
							className,
							error && 'border-red-500',
							variant === 'outlined' && 'border-2',
							variant === 'underlined' &&
								'flex h-9 w-full rounded-md border-b border-slate-200 bg-red-500 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300'
						)}
						{...props}
					/>
				)}

				<div>
					{error && touched && (
						<div className='mt-1 text-xs text-red-500'>{error}</div>
					)}
				</div>
				<div>
					{subText && (
						<div className='ml-3 mt-1 text-xs text-slate-500'>{subText}</div>
					)}
				</div>
			</div>
		)
	}
)

Input.displayName = 'Input'

export { Input }
